'use client';

import { twMerge } from 'tailwind-merge';
import { Icon, IconName } from '~/components/core/Icon';
import { useI18n } from '~/locales/client';
import Button from '../buttons/Button';
import Flex from '../flex/Flex';
import Text from '../texts/Text';

type ErrorMessageClientProps = {
  iconName?: string;
  iconSize?: number;
  iconColor?: string;
  iconClassName?: string;
  iconContainerClassName?: string;
  titleKey: string;
  titleClassName?: string;
  subtitleKey?: string;
  subtitleClassName?: string;
  btnText?: string;
  children?: React.ReactNode;
  className?: string;
  reset?: () => void;
  showRefreshBtn?: boolean;
};

/**
 * Client component for error message.
 * Shows error message with icon, title, subtitle and children
 * @param iconName - Icon name
 * @param iconSize - Icon size
 * @param iconColor - Icon color
 * @param iconClassName - Icon class name
 * @param iconContainerClassName - Icon container class name
 * @param titleKey - Title key
 * @param titleClassName - Title class name
 * @param subtitleKey - Subtitle key
 * @param subtitleClassName - Subtitle class name
 * @param btnText - Button text
 * @param children - Children
 * @param className - Class name
 * @param reset - Reset function
 * @param showRefreshBtn - Show refresh button
 */
const ErrorMessageClient = ({
  iconName = IconName.EmptyNotFound,
  iconSize = 80,
  iconColor = 'none',
  iconClassName,
  iconContainerClassName,
  titleKey,
  titleClassName,
  subtitleKey,
  subtitleClassName,
  btnText = 'global.error.reload-page',
  children,
  className,
  reset,
  showRefreshBtn = true,
}: ErrorMessageClientProps) => {
  const translate = useI18n();
  const handleRefresh = () => {
    reset && reset();
    window.location.reload();
  };

  return (
    <Flex
      className={twMerge(
        'w-full h-full flex-col justify-center items-center gap-6 self-center',
        className
      )}
    >
      <Flex
        className={twMerge(
          'justify-center items-center rounded-full p-2 h-20 w-20 bg-gray-50',
          iconContainerClassName
        )}
      >
        <Icon
          name={iconName}
          size={iconSize}
          color={iconColor}
          className={twMerge('flex justify-center items-center', iconClassName)}
        />
      </Flex>
      <Text className={twMerge('text-gray-700 font-medium', titleClassName)}>
        {translate(titleKey)}
      </Text>
      {subtitleKey && (
        <Text
          className={twMerge('text-gray-700 font-medium', subtitleClassName)}
        >
          {translate(subtitleKey)}
        </Text>
      )}
      {showRefreshBtn && (
        <Button
          onClick={handleRefresh}
          text={translate(btnText)}
          textClassName="text-gray-800"
          buttonClassName="w-fit bg-white border hover:bg-gray-100 py-2"
        />
      )}
      {children}
    </Flex>
  );
};

export default ErrorMessageClient;
