export type SearchParamsTypes = {
  [key: string]: string | string[] | undefined;
};

/**
 * The types of buttons that can be rendered.
 * - primary: A primary button with a background color of secondary and white text.
 * - default: A default button with a background color of gray-50 and gray-900 text.
 * - disabled: A disabled button with a background color of gray-200 and gray-400 text.
 */
export enum ButtonTypesEnum {
  primary = 'primary',
  default = 'default',
  disabled = 'disabled',
  secondary = 'secondary',
  noStyle = 'noStyle',
}

export type TranslateType = (
  key: string,
  params?: Record<string, unknown>
) => string;
